import {
  ChevronDownIcon,
  ChevronRightIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Header from "../Header";
import Loading from "../Loading";
import DeleteArtist from "./DeleteArtist";
import { PencilIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { debounce } from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import { setOpenModal } from "../../stores/modal";
import { useCookies } from "react-cookie";
import { getArtist } from "../../utils/lib/get-artist";
import { getArtistProducts } from "../../utils/lib/get-artist-products";

const initialFormModel = {
  name: "",
  countryOfOrigin: "",
  contactDetails: "",
  bookingAgent: "",
  biography: "",
};

export default function ArtistDetail() {
  const { id } = useParams();
  const { credential } = useSelector((state) => state.user);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [emptyValue, setEmptyValue] = useState(false);

  // artist info
  const [artist, setArtist] = useState(initialFormModel);
  const [artistProducts, setArtistProducts] = useState([]);
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [contact, setContact] = useState("");
  const [agent, setAgent] = useState("");
  const [biography, setBiography] = useState("");
  const [ISNI, setISNI] = useState("");
  const [biographyModal, setBiographyModal] = useState(false);
  const [identifierType, setIdentifierType] = useState([]);

  //identifiers
  const identifiers = [
    "Apple Music",
    "Audomack",
    "Spotify",
    "SoundCloud",
    "Spotify DJ Mixes opt-in",
  ];
  const [addIdentifier, setAddIdentifier] = useState(false);
  const [filteredIdentifiers, setFilteredIdentifiers] = useState();
  const [editIdentifier, setEditIdentifier] = useState(false);
  const [editingIdentifierValue, setEditingIdentifierValue] = useState(null);
  const [editingIdentifierId, setEditingIdentifierId] = useState(null);
  const [editedIdentifierName, setEditedIdentifierName] = useState();
  const [openCreateIdentifier, setOpenCreateIdentifier] = useState(false);
  const [createIdentifierValue, setCreateIdentifierValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIdentifier, setSelectedIdentifier] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletingIdentifierId, setDeletingIdentifierId] = useState("");

  //translations
  const [translations, setTranslations] = useState([]);
  const [addTranslation, setAddTranslation] = useState(false);
  const [languageNames, setLanguageNames] = useState([]);
  const [filteredLanguage, setFilteredLanguage] = useState();
  const [searchQueryTranslation, setSearchQueryTranslation] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [openTranslationModal, setOpenTranslationModal] = useState(false);
  const [editTranslation, setEditTranslation] = useState(false);
  const [editingArtistName, setEditingArtistName] = useState(null);
  const [editedArtistName, setEditedArtistName] = useState();
  const [editAddedTranslation, setEditAddedTranslation] = useState(false);
  const [editingTranslationId, setEditingTranslationId] = useState("");
  const [closeDeleteModal, setCloseDeleteModal] = useState(false);
  const [deleteModalTranslation, setDeleteModalTranslation] = useState(false);
  const [deletingTranslationId, setDeletingTranslationId] = useState("");
  const [handleModalT, setHandleModalT] = useState(false);
  const [cookie, setCookie] = useCookies(["selectedPartnerId"]);

  const headerSet = {
    Authorization: `Bearer ${credential}`,
    // PartnerId: getPartnerIds(partnerIds),
  };

  // Fetch artist details
  useEffect(() => {
    fetchArtist();
  }, []);

  const fetchArtist = async () => {
    const headers = {
      ...headerSet,
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const artist = await getArtist(headers, id);
      setArtist(artist);
      setLoading(false);

      const artistProductsData = await getArtistProducts(headers, artist.id);
      setArtistProducts(artistProductsData);

    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error);
    }
  };

  const artistName = artist.name;
  const artistCountry = artist.countryOfOrigin;
  const artistContact = artist.contactDetails;
  const artistAgency = artist.bookingAgent;
  const artistBiography = artist.biography;
  const artistISNI = artist.isni;
  const artistIdentifierType = artist.personIdentifiers;
  const artistTranslations = artist.translations;
  useEffect(() => {
    if (!artistName) {
      return;
    }

    setName(artistName);
    setCountry(artistCountry);
    setContact(artistContact);
    setAgent(artistAgency);
    setBiography(artistBiography);
    setIdentifierType(artistIdentifierType);
    setTranslations(artistTranslations);
    setISNI(artistISNI);
  }, [
    artistISNI,
    artistName,
    artistCountry,
    artistContact,
    artistAgency,
    artistBiography,
    artistIdentifierType,
    artistTranslations,
  ]);

  const onSaveClick = () => {
    debouncedUpdate(name, country, contact, agent, biography, ISNI);
  };

  //overview
  const debouncedUpdate = useCallback(
    debounce(
      async (newName, newCountry, newContact, newAgent, newBiography, newISNI) => {
        const headers = {
          ...headerSet,
          partnerId: cookie.selectedPartnerId,
        };

        const newArtist = {
          name: newName,
          countryOfOrigin: newCountry,
          contactDetails: newContact,
          bookingAgent: newAgent,
          biography: newBiography,
          isni: newISNI,
        };

        try {
          await axios.put(
            `https://music.videomite.tech/api/artist/${id}`,
            newArtist,
            { headers }
          );
          toast.success("Artist updated successfully");
        } catch (error) {
          console.error("API isteği sırasında bir hata oluştu:", error);
        }
      },
      1000
    ),
    []
  );

  const onNameChange = (evt) => {
    const newName = evt.target.value;
    setName(newName);
    debouncedUpdate(newName, country, contact, agent, biography, ISNI);
  };

  const onCountryChange = (evt) => {
    const newCountry = evt.target.value;
    setCountry(newCountry);
    debouncedUpdate(name, newCountry, contact, agent, biography, ISNI);
  };

  const onContactChange = (evt) => {
    const newContact = evt.target.value;
    setContact(newContact);
    debouncedUpdate(name, country, newContact, agent, biography, ISNI);
  };

  const onAgentChange = (evt) => {
    const newAgent = evt.target.value;
    setAgent(newAgent);
    debouncedUpdate(name, country, contact, newAgent, biography, ISNI);
  };

  const onBioChange = (evt) => {
    const newBiography = evt.target.value;
    setBiography(newBiography);
    debouncedUpdate(name, country, contact, agent, newBiography, ISNI);
  };

  const onISNIChange = (evt) => {
    const newISNI = evt.target.value;
    setISNI(newISNI);
    debouncedUpdate(name, country, contact, agent, biography, newISNI);
  };

  const closeBiographyModal = () => {
    setBiographyModal(false);
  };

  //identifier
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const handleSelectedIdentifier = (identifier) => {
    setSelectedIdentifier(identifier);
    setOpenCreateIdentifier(true);
    closeIdentifierModal();
  };

  const closeIdentifierModal = () => {
    setAddIdentifier(false);
    setSearchQuery("");
    setEditIdentifier(false);
  };

  const handleChangeIdentifier = (e) => {
    const { value } = e.target;

    setEditedIdentifierName(value);
    setEditingIdentifierValue(value);
    setCreateIdentifierValue(value);
  };

  //edited added identifier data
  const editedAddedIdentifierData = async () => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const response = await axios.put(
        `https://music.videomite.tech/api/artist/${id}/personIdentifierRelation/${editingIdentifierId}`,
        {
          identifierValue: editingIdentifierValue,
        },
        {
          headers,
        }
      );
      const updatedIdentifierValue = response.data;
      identifierType(updatedIdentifierValue);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  function selectPeopleNameForEditing(identifierValue, identifierId) {
    setEditingIdentifierValue(identifierValue);
    setEditingIdentifierId(identifierId);
  }

  const handleDeleteModal = (identifierId) => {
    setDeleteModal(!deleteModal);
    setDeletingIdentifierId(identifierId);
  };

  //post identifier data
  const createIdentifierData = async () => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };

    try {
      const response = await axios.post(
        `https://music.videomite.tech/api/artist/${id}/personIdentifierRelation`,
        {
          identifierType: selectedIdentifier,
          identifierValue: createIdentifierValue,
        },
        {
          headers,
        }
      );
      const createdIdentifier = response.data;
      setIdentifierType(createdIdentifier);
      dispatch(setOpenModal(false));
      closeModal();
      window.location.reload();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.errors["$.identifierType"][0]);
      }
    }
  };

  const closeModal = () => {
    dispatch(setOpenModal(false));
    setEditIdentifier(false);
    setEditedIdentifierName("");
  };

  useEffect(() => {
    if (!identifierType) return;

    if (!Array.isArray(identifierType)) {
      console.error("identifierType dizi olmalı:", identifierType);
      return;
    }
    const addedIdentifier = identifierType.map((idn) =>
      idn.identifierType.toLowerCase()
    );

    const searchFilteredIdentifiers = identifiers.filter(
      (ide) =>
        ide.toLowerCase().includes(searchQuery.toLowerCase()) &&
        !addedIdentifier.includes(ide.toLowerCase())
    );

    setFilteredIdentifiers(searchFilteredIdentifiers);
  }, [searchQuery, identifierType]);

  //delete added identifier data
  const deleteAddedIdentifierData = async () => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const response = await axios.delete(
        `https://music.videomite.tech/api/artist/${id}/personIdentifierRelation/${deletingIdentifierId}`,
        {
          headers,
        }
      );
      const deleteIdentifierData = response.data;
      setIdentifierType(deleteIdentifierData);
      setLoading(false);
      handleDeleteModal();
      window.location.reload();
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error);
      setLoading(true);
    }
  };

  //Translations
  const handleTranslationSearch = (event) => {
    const { value } = event.target;
    setSearchQueryTranslation(value);
  };

  const handleSelectedLanguage = (language) => {
    setSelectedLanguage(language);
    setOpenTranslationModal(true);
  };

  const closeTranslationModal = () => {
    setAddTranslation(false);
    setSearchQueryTranslation("");
    setEditTranslation(false);
  };

  const closeModalT = () => {
    setOpenTranslationModal(false);
    setHandleModalT(false);
    setEditTranslation(false);
    setEditingArtistName("");
  };

  const handleChangeTranslation = (e) => {
    const { value } = e.target;
    setEditedArtistName(value);
    setEditedArtistName(value);
  };

  //post translation data
  const createTranslationData = async () => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const response = await axios.post(
        `https://music.videomite.tech/api/artist/${id}/translation`,
        {
          language: selectedLanguage,
          artistName: editedArtistName,
        },
        {
          headers,
        }
      );
      const createdTranslation = response.data;
      setTranslations(createdTranslation);
      dispatch(setOpenModal(false));
      closeModalT();
      closeTranslationModal();
      window.location.reload();
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error);
    }
  };

  const openEditTranslationModal = () => {
    setEditTranslation(true);
  };

  function selectArtistNameForEditing(artistName, translationId) {
    setEditAddedTranslation(!editAddedTranslation);
    setEditingArtistName(artistName);
    setEditingTranslationId(translationId);
  }

  //edited added translation data
  const editedAddedTranslationData = async () => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const response = await axios.put(
        `https://music.videomite.tech/api/artist/${id}/translation/${editingTranslationId}`,
        {
          artistName: editingArtistName,
        },
        {
          headers,
        }
      );
      const updatedTranslationName = response.data;
      setTranslations(updatedTranslationName);
      setLoading(false);
      setEditAddedTranslation(false);
      window.location.reload();
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error);
      setLoading(true);
    }
  };

  const closeModalDeleteTranslation = () => {
    setCloseDeleteModal(false);
    setEditTranslation(false);
    setEditedArtistName("");
  };

  //delete added translation data
  const deleteAddedTranslationData = async () => {
    const headers = {
      ...headerSet,
      Authorization: `Bearer ${credential}`,
      "Content-Type": "application/json",
      partnerId: cookie.selectedPartnerId,
    };
    try {
      const response = await axios.delete(
        `https://music.videomite.tech/api/artist/${id}/translation/${deletingTranslationId}`,
        {
          headers,
        }
      );
      const deleteTranslationData = response.data;
      setTranslations(deleteTranslationData);
      setLoading(false);
      handleDeleteModal();
      window.location.reload();
    } catch (error) {
      console.error("API isteği sırasında bir hata oluştu:", error);
      setLoading(true);
    }
  };

  const handleDeleteModalTranslation = (translationId) => {
    setDeleteModalTranslation(!deleteModalTranslation);
    setDeletingTranslationId(translationId);
  };

  useEffect(() => {
    const fetchLang = async () => {
      const headers = {
        ...headerSet,
      };
      try {
        const response = await axios.get(
          `https://music.videomite.tech/api/language`,
          { headers }
        );
        const languages = response.data;
        setLanguageNames(languages);

        setLoading(false);
      } catch (error) {
        console.error("API isteği sırasında bir hata oluştu:", error);
      }
    };

    fetchLang();
  }, [addTranslation]);

  useEffect(() => {
    if (!artistTranslations) return;

    if (!Array.isArray(artistTranslations)) {
      console.error("artistTranslations dizi olmalı:", artistTranslations);
      return;
    }

    const addedLanguageNames = artistTranslations.map((translation) =>
      translation.language.toLowerCase()
    );

    const searchFilteredLanguages = languageNames.filter(
      (lang) =>
        lang.toLowerCase().includes(searchQueryTranslation.toLowerCase()) &&
        !addedLanguageNames.includes(lang.toLowerCase())
    );

    setFilteredLanguage(searchFilteredLanguages);
  }, [searchQueryTranslation, artistTranslations]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  const fileSelectedHandler = async (event) => {
    try {
      setImageLoading(true)
      const headers = {
        ...headerSet,
        Authorization: `Bearer ${credential}`,
        partnerId: cookie.selectedPartnerId,
      };

      const formData = new FormData();
      formData.append('photoFile', event.target.files[0]);

      const response = await fetch(`https://music.videomite.tech/api/artist/${id}/photo`, {
        method: 'POST',
        body: formData,
        headers
      });

      if (response.ok) {
        const responseData = await response.json();
        setSelectedFile(responseData.photoUrl);
        setImageLoading(false)
      } else {
        setImageLoading(false)
        console.error('Dosya yükleme başarısız:', response.statusText);
      }
    } catch (error) {
      setImageLoading(false)
      console.error('Dosya yükleme hatası:', error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {imageLoading && <Loading />}
      <ToastContainer autoClose={3000} />
      <Header />
      <DeleteArtist />
      <div className="mx-auto max-w-7xl p-3 lg:px-8">
        <div className="flex items-center gap-2 py-6">
          <div>
            <Link to="/catalog/products" className="text-[#555555] text-sm">
              Catalog
            </Link>
          </div>
          <ChevronRightIcon className="w-4 h-4 text-[#555555]"/>
          <div>
            <Link to="/catalog/artists" className="text-[#555555] text-sm">
              Artist
            </Link>
          </div>
          <ChevronRightIcon className="w-4 h-4 text-[#555555]"/>
          <div>
            <Link
                to={`/catalog/artists/${id}`}
                className="text-[#555555] text-sm"
            >
              {artistName}
            </Link>
          </div>
          <div className="flex ml-auto">
            <button
                onClick={onSaveClick}
                className="ml-6 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Save Changes
            </button>
          </div>
        </div>
        <div className="bg-white border border-[#e2e2e2]">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-20 h-20 rounded-none cursor-pointer bg-[#dcd6d6]"
              >
                <div
                    className="flex flex-col items-center justify-center py-4 hover:before:content-['update'] relative">
                  {
                    selectedFile || artist.photoUrl ? (
                        <img
                            className='w-20 h-20 object-cover shadow-inner'
                            src={selectedFile ?? artist.photoUrl} alt=""/>
                    ) : (
                        <UserIcon className="text-[#b9b3b3] shadow-inner h-20 w-20 p-2"/>
                    )
                  }
                </div>
                <input onChange={fileSelectedHandler} id="dropzone-file" type="file" className="hidden"/>
              </label>
              <div className="flex flex-col gap-1">
                <span className="text-sm font-semibold">{artist.name}</span>
                <span className="text-xs font-medium text-primary">
                  {artistProducts.length} product{artistProducts.length > 1 ? `s` : ``}
                </span>
              </div>
            </div>
            <div className="text-xs font-light text-[#BBBBBB] px-6">
              ID: <span className="pl-2 text-[#212121]">{artist.id}</span>
            </div>
          </div>
        </div>

        {/* OVERVIEW */}
        <div className="bg-white border border-[#e2e2e2] py-4 px-6 mt-12">
          <div className="flex items-center gap-2  px-6 text-xs text-[#555555B3] uppercase">
            overview
          </div>
          <div className="grid grid-cols-2 gap-x-36 gap-y-4 px-6 py-4">
            <div>
              <label htmlFor="name" className="text-xs text-[#5F5F60B3]">
                Name*
              </label>
              <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={onNameChange}
                  value={name}
                  className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div>
              <label
                  htmlFor="countryOfOrigin"
                  className="text-xs text-[#5F5F60B3]"
              >
                Country
              </label>
              <input
                  type="text"
                  name="countryOfOrigin"
                  id="countryOfOrigin"
                  onChange={onCountryChange}
                  value={country}
                  className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div>
              <label
                  htmlFor="contactDetails"
                  className="text-xs text-[#5F5F60B3]"
              >
                Contact Detail
              </label>
              <input
                  type="text"
                  name="contactDetails"
                  id="contactDetails"
                  onChange={onContactChange}
                  value={contact}
                  className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div>
              <label
                  htmlFor="bookingAgent"
                  className="text-xs text-[#5F5F60B3]"
              >
                Booking Agent
              </label>
              <input
                  type="text"
                  name="bookingAgent"
                  id="bookingAgent"
                  onChange={onAgentChange}
                  value={agent}
                  className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2"
              />
            </div>
            <div className="relative">
              <label htmlFor="biography" className="text-xs text-[#5F5F60B3]">
                Biography
              </label>
              <input
                  type="text"
                  name="biography"
                  id="biography"
                  readOnly
                  onChange={onBioChange}
                  value={biography}
                  className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2 pr-6 cursor-default"
              />
              <PencilIcon
                  onClick={() => setBiographyModal(true)}
                  className="absolute right-0 top-6 w-6 h-6 text-primary"
              />
            </div>

            <div className="relative">
              <label htmlFor="biography" className="text-xs text-[#5F5F60B3]">
                ISNI
              </label>
              <input
                  type="text"
                  name="isni"
                  id="isni"
                  onChange={onISNIChange}
                  value={ISNI}
                  className="block w-full border-b border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary hover:border-b-2 focus:border-primary focus:border-b-2 pr-6 cursor-default"
              />
            </div>
          </div>
        </div>
        {biographyModal && (
            <div>
              <Transition.Root show={biographyModal} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={closeBiographyModal}
                >
                  <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel
                            className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                          <div>
                            <div className="text-lg font-normal px-4 pt-5">
                              <h1>Biography</h1>
                            </div>
                            <div className="mt-3 sm:mt-5 flex items-center gap-3 px-4 pb-4">
                              <div className="relative w-full">
                              <textarea
                                  name="biography"
                                  id="biography"
                                  onChange={onBioChange}
                                  value={biography}
                                  className="block w-full border border-gray-300 p-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 hover:border-primary focus:border-primary"
                              />
                              </div>
                            </div>
                          </div>
                          <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                            <div></div>
                            <div className="flex items-center gap-4">
                              <button
                                  type="button"
                                  className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                  onClick={closeBiographyModal}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            </div>
        )}


        {/* ARTIST PRODUCTS */}

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg border border-[#E2E2E2] mt-4">
          <div className="w-full text-sm text-left">
            <div
                className="flex items-center h-[50px] py-3 p-6 text-[#212121] font-light border-b border-[#E2E2E2] bg-white text-xs">
              <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                  Title
                </div>
              </div>
              <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                  Artist
                </div>
              </div>
              <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                  BARCODE CAT. NR
                </div>
              </div>
              <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                  Release Version
                </div>
              </div>
              <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                  Date Added
                </div>
              </div>
              <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase font-light">
                  Release Date
                </div>
              </div>
            </div>
            <div>
              {artistProducts.map((item, index) => (
                  <div
                      key={item.id}
                      className="flex items-center h-[50px] py-3 p-6 text-primary font-light border-b border-[#E2E2E2] bg-white text-xs"
                  >
                    <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                      <div className="inline-block max-w-full overflow-hidden whitespace-nowrap font-light">
                        <Link
                            to={`/catalog/products/${item.id}`}>{item.title}</Link>
                      </div>
                    </div>
                    <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                      <div className="inline-block max-w-full overflow-hidden whitespace-nowrap font-light">
                          <span>
                            {item.displayArtists ? item.displayArtists : "-"}
                          </span>
                      </div>
                    </div>
                    <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                      <div className="inline-block max-w-full overflow-hidden whitespace-nowrap font-light">
                          <span>
                            {item.displayArtists ? item.displayArtists : "-"}
                          </span>
                      </div>
                    </div>
                    <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                      <div className="inline-block max-w-full overflow-hidden whitespace-nowrap font-light">
                          <span>
                            {item.releaseVersion ? item.releaseVersion : "-"}
                          </span>
                      </div>
                    </div>
                    <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                      <div className="inline-block max-w-full overflow-hidden whitespace-nowrap font-light">
                          <span>
                            {item.createdTime ? item.createdTime : "-"}
                          </span>
                      </div>
                    </div>
                    <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                      <div className="inline-block max-w-full overflow-hidden whitespace-nowrap font-light">
                          <span>
                            {item.releaseDate ? item.releaseDate : "-"}
                          </span>
                      </div>
                    </div>
                  </div>
              ))}
            </div>
          </div>
        </div>

        {/* IDENTIFIER */}
        <div className="bg-white border border-[#e2e2e2] py-4 px-6 mt-12">
          <div className="flex items-center gap-2  px-6 text-xs text-[#555555B3] uppercase mb-4">
            Custom Identifiers
          </div>
          <div
              className="flex items-center h-[50px] px-6 py-4 text-[#212121] font-light border-b border-[#E2E2E2] bg-white text-xs">
            <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
              <span className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                Identifier Type
              </span>
            </div>
            <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
              <span className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                Identifier
              </span>
            </div>
            <div className="flex-[0_1_200px] mr-[10px] text-xs text-right relative">
              <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                <div
                    onClick={() => setAddIdentifier(true)}
                    className="flex items-center gap-2  hover:bg-gray-100 p-2 rounded-primary cursor-pointer"
                >
                  <PlusIcon className="w-5 h-5 text-primary"/>
                  <span className="text-right">Add Identifier</span>
                  <ChevronDownIcon className="w-5 h-5- text-primary"/>
                </div>
                {addIdentifier && (
                    <div
                        className="z-10 absolute w-[300px] shadow-primary bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                      <div className="py-2 px-4">
                        <div className="flex flex-col">
                          <div
                              className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                            <input
                                type="text"
                                onChange={handleSearch}
                                placeholder="Type here to search for identifier"
                                className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                            />
                          </div>
                          <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]"/>
                          <ul className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                            {filteredIdentifiers.map((identifier, index) => (
                                <div
                                    onClick={() =>
                                        handleSelectedIdentifier(identifier)
                                    }
                                    key={index}
                                    className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                                        index === 0 && "bg-gray-200"
                                    }`}
                                >
                                  <span className="capitalize">{identifier}</span>
                                </div>
                            ))}
                          </ul>
                          <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]"/>
                          <div
                              onClick={closeIdentifierModal}
                              className="flex flex-row-reverse pt-2"
                          >
                            <button
                                className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                )}
              </div>
            </div>
          </div>
          {!Array.isArray(identifierType) && (
              <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center">
                No items available
              </div>
          )}
          {identifierType &&
              identifierType.map((identifier, index) => {
                return (
                    <div
                        key={identifier.identifierType}
                        className={
                          index % 2 === 0
                              ? "flex items-center h-[50px] py-4 p-6 text-[#212121] font-light bg-white text-xs"
                              : "bg-[#dc64780f] flex items-center h-[50px] py-4 p-6 text-[#212121] font-light text-xs"
                        }
                    >
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                    <span className="inline-block max-w-full overflow-hidden whitespace-nowrap">
                      {identifier.identifierType}
                    </span>
                      </div>
                      <div className="flex-[1_1_200px] text-xs text-left">
                        {identifier.id === editingIdentifierId ? (
                            <input
                                type="text"
                                defaultValue={identifier.identifierValue}
                                onChange={handleChangeIdentifier}
                                className="w-2/3 border-b-2 py-1 outline-none bg-transparent focus:border-b-primary hover:border-b-primary"
                            />
                        ) : (
                            <span className="inline-block max-w-full overflow-hidden whitespace-nowrap">
                        {identifier.identifierValue}
                      </span>
                        )}
                      </div>
                      <div className="flex-[0_1_200px] mr-[20px] text-xs text-right relative">
                        <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                          {identifier.id === editingIdentifierId ? (
                              <div className="flex items-center gap-2">
                                <div>
                                  <button
                                      onClick={editedAddedIdentifierData}
                                      className="bg-transparent py-2 px-4 rounded-primary text-[14px] text-primary uppercase hover:bg-[#dc64780f]"
                                  >
                                    Save
                                  </button>
                                </div>
                                <div
                                    onClick={() => setEditingIdentifierId(null)}
                                    className="cursor-pointer hover:bg-gray-100 py-2 px-1 rounded-full"
                                >
                                  <XMarkIcon className="w-6 h-6 text-gray-500"/>
                                </div>
                              </div>
                          ) : (
                              <div className="flex items-center gap-6">
                                <div
                                    onClick={() =>
                                        selectPeopleNameForEditing(
                                            identifier.identifierValue,
                                            identifier.id
                                        )
                                    }
                                    className="p-2 hover:bg-gray-100 rounded-full cursor-pointer"
                                >
                                  <PencilIcon className="w-6 h-6 text-gray-500"/>
                                </div>
                                <div
                                    onClick={() => handleDeleteModal(identifier.id)}
                                    className="p-2 hover:bg-gray-100 rounded-full cursor-pointer"
                                >
                                  <TrashIcon className="w-6 h-6 text-gray-500"/>
                                </div>
                              </div>
                          )}
                        </div>
                      </div>
                    </div>
                );
              })}
          {openCreateIdentifier && (
              <div className="flex items-center h-[50px] py-4 p-6 text-[#212121] font-light bg-white text-xs">
                <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                <span className="inline-block max-w-full overflow-hidden whitespace-nowrap">
                  {selectedIdentifier}
                </span>
                </div>
                <div className="flex-[1_1_200px] text-xs text-left">
                  <input
                      type="text"
                      defaultValue={createIdentifierValue}
                      onChange={handleChangeIdentifier}
                      className="w-2/3 border-b-2 py-1 outline-none focus:border-b-primary hover:border-b-primary"
                  />
                </div>
                <div className="flex-[0_1_200px] mr-[20px] text-xs text-right relative">
                  <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                    <div className="flex items-center gap-2">
                      <div>
                        <button
                            onClick={createIdentifierData}
                            className="bg-transparent py-2 px-4 rounded-primary text-[14px] text-primary uppercase hover:bg-[#dc64780f]"
                        >
                          Save
                        </button>
                      </div>
                      <div
                          onClick={() => setOpenCreateIdentifier(false)}
                          className="cursor-pointer hover:bg-gray-100 py-2 px-1 rounded-full"
                      >
                        <XMarkIcon className="w-6 h-6 text-gray-500"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          )}
        </div>
        {deleteModal && (
            <>
              <Transition.Root show={deleteModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                  </Transition.Child>

                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel
                            className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                          <div>
                            <div className="text-lg font-normal px-4 pt-5">
                              <h1>Remove Identifier</h1>
                              <p className="text-sm font-light py-4 text-[#757575] flex">
                                Are you sure you want to remove this identifier
                                from the Product?
                              </p>
                            </div>
                          </div>
                          <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                            <div></div>
                            <div className="flex items-center gap-4">
                              <button
                                  type="button"
                                  className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase outline-none hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                  onClick={handleDeleteModal}
                              >
                                Cancel
                              </button>
                              <button
                                  type="button"
                                  className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-white bg-primary uppercase hover:bg-primary/80 sm:col-start-1 sm:mt-0"
                                  onClick={deleteAddedIdentifierData}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>
            </>
        )}

        {/* TRANSLATION */}
        <div className="bg-white border border-[#e2e2e2] py-4 px-6 mt-12">
          <div className="flex items-center gap-2  px-6 text-xs text-[#555555B3] uppercase mb-4">
            Translations
          </div>
          <div
              className="flex items-center h-[50px] py-3 p-6 text-[#212121] font-light border-b border-[#E2E2E2] bg-white text-xs">
            <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
              <span className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                Language
              </span>
            </div>
            <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
              <span className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                Artist Name
              </span>
            </div>
            <div className="flex-[0_1_200px] mr-[10px] text-xs text-right relative">
              <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                <div
                    onClick={() => setAddTranslation(true)}
                    className="flex items-center gap-2  hover:bg-gray-100 p-2 rounded-primary cursor-pointer"
                >
                  <PlusIcon className="w-5 h-5 text-primary"/>
                  <span className="text-right">Add Translation</span>
                  <ChevronDownIcon className="w-5 h-5- text-primary"/>
                </div>
                {addTranslation && (
                    <div
                        className="z-10 absolute w-[300px] shadow-primary bg-white text-[#212121] rounded-primary overflow-x-hidden overflow-y-auto  min-h-[16px] min-w-[16px]">
                      <div className="py-2 px-4">
                        <div className="flex flex-col">
                          <div
                              className="mb-[15px] text-[#212121] relative text-[14px] cursor-text inline-flex items-center font-normal leading-[1.1876em]">
                            <input
                                type="text"
                                onChange={handleTranslationSearch}
                                placeholder="Type here to search for language"
                                className="w-full text-[#1F1F21] border-0 h-[2.1876em] m-0 block py-1.5 min-w-0 bg-none outline-none border-b-2 border-gray-200 hover:border-primary focus:border-primary"
                            />
                          </div>
                          <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]"/>
                          <div className="max-h-[300px] min-h-[80px] overflow-y-auto py-2 relative list-none">
                            {filteredLanguage.map((language, index) => (
                                <div
                                    onClick={() => handleSelectedLanguage(language)}
                                    key={index}
                                    className={`rounded-primary px-4 w-full flex relative text-left items-center py-3 justify-start no-underline cursor-pointer border-0 outline-0 hover:bg-gray-200 ${
                                        index === 0 && "bg-gray-200"
                                    }`}
                                >
                                  <span className="capitalize">{language}</span>
                                </div>
                            ))}
                            {!Array.isArray(filteredLanguage) && (
                                <div
                                    className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center capitalize">
                                  Could not find anything
                                </div>
                            )}
                          </div>
                          <hr className="-mx-3 border-none h-[1px] m-0 flex-shrink-0 bg-[#0000001f]"/>
                          <div
                              onClick={closeTranslationModal}
                              className="flex flex-row-reverse pt-2"
                          >
                            <button
                                className="py-1.5 px-2 text-[#212121] h-9 text-[0.875rem] min-w-[64px] shadow-none font-medium leading-7 rounded-primary uppercase hover:bg-gray-100">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                )}
              </div>
            </div>
          </div>
          {!artistTranslations && (
              <div className="h-[60px] text-[#616161] font-light w-full flex italic items-center justify-center">
                No items available
              </div>
          )}

          {Array.isArray(artistTranslations) &&
              artistTranslations.map((translation, index) => {
                return (
                    <div
                        key={translation.id}
                        className={
                          index % 2 === 0
                              ? "flex items-center h-[50px] py-4 p-6 text-[#212121] font-light bg-white text-xs"
                              : "bg-[#dc64780f] flex items-center h-[50px] py-4 p-6 text-[#212121] font-light text-xs"
                        }
                    >
                      <div className="flex-[1_1_200px] mr-[10px] text-xs text-left">
                    <span className="inline-block max-w-full overflow-hidden whitespace-nowrap">
                      {translation.language}
                    </span>
                      </div>
                      <div className="flex-[1_1_200px] text-xs text-left">
                    <span className="inline-block max-w-full overflow-hidden whitespace-nowrap">
                      {translation.artistName}
                    </span>
                      </div>
                      <div className="flex-[0_1_200px] mr-[20px] text-xs text-right relative">
                        <div className="inline-block max-w-full overflow-hidden whitespace-nowrap uppercase">
                          <div className="flex items-center gap-6">
                            <div
                                onClick={() =>
                                    selectArtistNameForEditing(
                                        translation.artistName,
                                        translation.id
                                    )
                                }
                                className="p-2 hover:bg-gray-100 rounded-full cursor-pointer"
                            >
                              <PencilIcon className="w-6 h-6 text-gray-500"/>
                            </div>
                            <div
                                onClick={() =>
                                    handleDeleteModalTranslation(translation.id)
                                }
                                className="p-2 hover:bg-gray-100 rounded-full cursor-pointer"
                            >
                              <TrashIcon className="w-6 h-6 text-gray-500"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                );
              })}

          {deleteModalTranslation && (
              <>
                <Transition.Root show={deleteModalTranslation} as={Fragment}>
                  <Dialog
                      as="div"
                      className="relative z-10"
                      onClose={closeModalDeleteTranslation}
                  >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel
                              className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                            <div>
                              <div className="text-lg font-normal px-4 pt-5">
                                <h1>Remove translation</h1>
                                <p className="text-sm font-light py-4 text-[#757575] flex">
                                  Are you sure you want to remove this translation
                                  from the Product?
                                </p>
                              </div>
                            </div>
                            <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                              <div></div>
                              <div className="flex items-center gap-4">
                                <button
                                    type="button"
                                    className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase outline-none hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                    onClick={handleDeleteModal}
                                >
                                  Cancel
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-white bg-primary uppercase hover:bg-primary/80 sm:col-start-1 sm:mt-0"
                                    onClick={deleteAddedTranslationData}
                                >
                                  Confirm
                                </button>
                              </div>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>
              </>
          )}
          {openTranslationModal && (
              <>
                <Transition.Root show={openTranslationModal} as={Fragment}>
                  <Dialog
                      as="div"
                      className="relative z-10"
                      onClose={closeModalT}
                  >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel
                              className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                            <div>
                              <div className="text-lg font-normal px-4 pt-5">
                                <h1>Add translations</h1>
                                <p className="text-sm font-light mt-2 text-[#757575] flex">
                                  Insert translations by clicking the{" "}
                                  <PencilIcon className="w-6 h-6 px-1"/> button
                                  and saving it.
                                </p>
                              </div>
                              <div className="relative w-ful">
                                <div className="overflow-visible">
                                  <div
                                      className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-light border-b border-[#e2e2e2] uppercase bg-white pb-4">
                                    <div className="flex-[1_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Original
                                    </span>
                                    </div>
                                    <div className="flex-[1.5_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Translation
                                    </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="relative w-ful py-4">
                                <div className="overflow-visible">
                                  <div
                                      className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-normal bg-white">
                                    <div className="flex-[1_1_200px] text-[12px] text-left px-4 ">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      {artistName}
                                    </span>
                                    </div>
                                    <div
                                        className="flex items-centert justify-between flex-[1.5_1_200px] text-[12px] text-left px-4 ">
                                      {editTranslation ? (
                                          <div className="flex items-center w-5/6 gap-4">
                                            <input
                                                type="text"
                                                defaultValue={editedArtistName}
                                                onChange={handleChangeTranslation}
                                                className="w-full border-b-2 outline-none focus:border-b-primary hover:border-b-primary"
                                            />
                                            <button
                                                onClick={createTranslationData}
                                                className="uppercase font-semibold text-primary hover:bg-primary/10 py-1 px-4 rounded-md"
                                            >
                                              Save
                                            </button>
                                          </div>
                                      ) : (
                                          <span
                                              className="flex-1 w-full overflow-hidden whitespace-nowrap text-ellipsis">
                                        {editedArtistName}
                                      </span>
                                      )}
                                      <div
                                          className="flex items-center justify-center cursor-pointer hover:bg-gray-100 py-1 px-4 rounded-primary">
                                        <PencilSquareIcon
                                            onClick={openEditTranslationModal}
                                            className="text-black w-6 h-6"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                              <div></div>
                              <div className="flex items-center gap-4">
                                <button
                                    type="button"
                                    className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase hover:bg-gray-200 sm:col-start-1 sm:mt-0 focus:outline-none"
                                    onClick={closeModalT}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>
              </>
          )}
          {editAddedTranslation && (
              <>
                <Transition.Root show={editAddedTranslation} as={Fragment}>
                  <Dialog
                      as="div"
                      className="relative z-10"
                      onClose={selectArtistNameForEditing}
                  >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel
                              className="relative transform overflow-hidden rounded-primary bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                            <div>
                              <div className="text-lg font-normal px-4 pt-5">
                                <h1>Add translations</h1>
                                <p className="text-sm font-light mt-2 text-[#757575] flex">
                                  Insert translations by clicking the{" "}
                                  <PencilIcon className="w-6 h-6 px-1"/> button
                                  and saving it.
                                </p>
                              </div>
                              <div className="relative w-ful">
                                <div className="overflow-visible">
                                  <div
                                      className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-light border-b border-[#e2e2e2] uppercase bg-white pb-4">
                                    <div className="flex-[1_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Original
                                    </span>
                                    </div>
                                    <div className="flex-[1.5_1_200px] text-[10px] text-left px-4 pt-5">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      Translation
                                    </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="relative w-ful py-4">
                                <div className="overflow-visible">
                                  <div
                                      className="flex items-center flex-row h-[40px] text-[#212121] text-[12px] font-normal bg-white">
                                    <div className="flex-[1_1_200px] text-[12px] text-left px-4 ">
                                    <span className="inline-block max-w-full text-ellipsis whitespace-nowrap">
                                      {artistName}
                                    </span>
                                    </div>
                                    <div
                                        className="flex items-centert justify-between flex-[1.5_1_200px] text-[12px] text-left px-4 ">
                                      {editTranslation ? (
                                          <div className="flex items-center w-5/6 gap-4">
                                            <input
                                                type="text"
                                                defaultValue={editingArtistName}
                                                onChange={handleChangeTranslation}
                                                className="w-full border-b-2 outline-none focus:border-b-primary hover:border-b-primary"
                                            />
                                            <button
                                                onClick={editedAddedTranslationData}
                                                className="uppercase font-semibold text-primary hover:bg-primary/10 py-1 px-4 rounded-md"
                                            >
                                              Save
                                            </button>
                                          </div>
                                      ) : (
                                          <span
                                              className="flex-1 w-full overflow-hidden whitespace-nowrap text-ellipsis">
                                        {editingArtistName}
                                      </span>
                                      )}
                                      <div
                                          className="flex items-center justify-center cursor-pointer hover:bg-gray-100 py-1 px-4 rounded-primary">
                                        <PencilSquareIcon
                                            onClick={openEditTranslationModal}
                                            className="text-black w-6 h-6"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" sm:flex items-center justify-between sm:gap-3 bg-[#eee] p-4">
                              <div></div>
                              <div className="flex items-center gap-4">
                                <button
                                    type="button"
                                    className="mt-3 inline-flex justify-center rounded-primary px-3 py-2 text-sm font-medium text-gray-900 uppercase outline-none hover:bg-gray-200 sm:col-start-1 sm:mt-0"
                                    onClick={selectArtistNameForEditing}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>
              </>
          )}
        </div>

        <div className="flex items-center gap-2 py-6">
          <div className="flex ml-auto">
            <button
                onClick={onSaveClick}
                className="ml-6 uppercase inline-flex gap-2 cursor-pointer items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
